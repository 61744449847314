import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: null,
  employee: [],
  employeeTarget: [],
  allEmployeeTarget: [],
  employeeactivity: [],
  employeeDiscussion: [],
  employeeefollowups: [],
  employeeAssihnment: [],
  unassignedvendor: [],
  assignedvendor: [],
  vendorDesignation: null,
  employeeOrder: [],
  employeebyId: null,
  usertraking: [],
  events: [],
  designation: [],
  attendanceList: [],
  attendanceReport: [],
  childUnAssign: [],
  childAssign: [],
  childDesignation: [],
  statusSuccess: '',
  noData: false,
  employeeTargtById: {},
  allConfigureTargetData: [],
  forTC_PC_Sales: {},
  currentEmployeeDesignation: null,
  deletedEmployeeByDesignation: [],
  usersListByStatus: [],
  territories: [],
};
const employee = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    startLoading(state, action) {
      state.isLoading = action.payload;
    },
    hasError(state, action) {
      state.error = action.payload;
    },
    getEmployee(state, action) {
      state.isLoading = false;
      state.employee = [...action.payload.user, ...state.employee];
      state.employeeCount = action.payload.count
      state.error = false;
    },
    clearEmployeeList(state) {
      state.employee = []
    },
    updateEmployee(state, action) {
      state.employee = state.employee.map(obj => [action.payload].find(o => o.id === obj.id) || obj);
    },
    getEmployeeById(state, action) {
      state.isLoading = false;
      state.employeebyId = action.payload;
      state.error = false;
    },
    getEmployeeDiscussion(state, action) {
      state.employeeDiscussion = action.payload;
      state.isLoading = false;
    },
    getEmployeeOrder(state, action) {
      state.employeeOrder = action.payload;
      state.isLoading = false;
    },
    getEmployeeTraking(state, action) {
      state.isLoading = false;
      state.usertraking = action.payload;
      state.error = false;
    },
    getEmployeeActivity(state, action) {
      state.isLoading = false;
      state.employeeactivity = action.payload;
      state.error = false;
    },
    getEmployeeAssignemnt(state, action) {
      state.employeeAssihnment = action.payload;
      state.isLoading = false;
    },
    getEmployeeTarget(state, action) {
      state.isLoading = false;
      state.employeeTarget = action.payload;
    },
    getAllEmployeeTarget(state, action) {
      state.isLoading = false;
      state.allEmployeeTarget = action.payload;
    },
    getEmployeeFolloups(state, action) {
      state.isLoading = false;
      state.employeeefollowups = action.payload;
    },

    updateEmployeeSuccess(state, action) {
      const event = action.payload;
      const updateEvent = state.events.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.events = updateEvent;
    },
    deleteEmployee(state, action) {
      state.employee = state.employee.filter((data) => data.id !== action.payload);
    },
    deleteEmployeeTarget(state, action) {
      state.allEmployeeTarget = state.allEmployeeTarget.filter((data) => data.target_id !== action.payload);
    },
    createEmployeeSuccess(state, action) {
      const newEvent = action.payload;
      state.isLoading = false;
      state.events = [...state.events, newEvent];
    },
    addNewEmployeeSuccess(state, action) {
      state.employee = [
        ...state.employee,
        {
          id: `e99f09a7-dd88-49d5-b1c8-1daf80c2d7b${state.employee.length + 1}`,
          employeeName: action.payload.employeeName,
          employeeCode: action.payload.employeeCode,
          designation: action.payload.designation,
          location: action.payload.location,
          state: action.payload.state,
          phoneNumber: action.payload.phoneNumber,
          password: action.payload.password,
          confirmPassword: action.payload.confirmPassword,
        },
      ];
    },
    editEmployeeSuccess(state, action) {
      state.employee = action.payload;
    },
    addNewEmployeeTargetSuccess(state, action) {
      state.employeeTarget = [
        ...state.employeeTarget,
        {
          id: `e99f09a7-dd88-49d5-b1c8-1daf80c2d7b${state.employeeTarget.length + 1}`,
          assignTo: action.payload.assignTo,
          designation: action.payload.designation,
          month: action.payload.month,
          targetAmount: action.payload.targetAmount,
        },
      ];
    },
    editEmployeeTargetSuccess(state, action) {
      state.employeeTarget = action.payload;
    },
    employeeBulkUpload(state, action) {
      state.isLoading = false;
      state.employee = [...state.employee, ...action.payload];
    },
    getEmployeeDesignationList(state, action) {
      state.isLoading = false;
      state.designation = action.payload;
    },
    getAttendanceList(state, action) {
      state.isLoading = false;
      state.attendanceList = action.payload;
      state.error = false;
    },
    downloadAttendanceReport(state, action) {
      state.isLoading = false;
      state.attendanceReport = action.payload;
      state.error = false;
    },
    getChildUnassignEmployees(state, action) {
      state.isLoading = false;
      state.childUnAssign = action.payload;
      state.error = false;
    },
    getChildAssignEmployees(state, action) {
      state.isLoading = false;
      state.childAssign = action.payload;
      state.error = false;
    },
    getChildDesignation(state, action) {
      state.isLoading = false;
      state.childDesignation = action.payload;
      state.error = false;
    },
    getResponse(state, action) {
      state.isLoading = false;
      state.statusSuccess = action.payload;
      state.error = false;
    },
    getVendorDesignationByEmployee(state, action) {
      state.vendorDesignationByEmployee = action.payload;
    },
    getVendoredesignationID(state, action) {
      state.vendorDesignation = action.payload;
    },
    getUnassignedVendorForEmployee(state, action) {
      state.unassignedvendor = action.payload;
    },
    getAssignedVendorForEmployee(state, action) {
      state.assignedvendor = action.payload;
    },
    setNoData(state, action) {
      state.noData = action.payload;
    },
    getEmployeeTargetById(state, action) {
      state.isLoading = false;
      state.employeeTargtById = action.payload;
      state.error = false;
    },
    configureTargetData(state, action) {
      state.isLoading = false;
      state.allConfigureTargetData = action.payload;
      state.error = false;
    },
    getEmployeeAllActivity(state, action) {
      state.isLoading = false;
      state.forTC_PC_Sales = action.payload;
      state.error = false;
    },
    updateEmployeeTargetReducer: (state, action) => {
      const currentState = current(state.allEmployeeTarget);
      const { targetId, updatedData } = action.payload;
      const updateData = currentState.map((item) => {
        if (item.target_id === targetId) {
          return {
            ...item,
            target_amount: updatedData.amount,
            month: updatedData.month,
            year: updatedData.year,
          };
        }
        return item;
      });
      state.allEmployeeTarget = updateData;
    },
    getCurrentEmployeeDesignation(state, action) {
      state.isLoading = false;
      state.currentEmployeeDesignation = action.payload;
    },
    getDeletedEmployeeByDesignationReducer(state, action) {
      state.isLoading = false;
      state.deletedEmployeeByDesignation = action.payload;
    },
    getUsersByStatusReducer(state, action) {
      state.isLoading = false;
      state.usersListByStatus = action.payload;
    },
    reduceUserFromRegistered(state, action) {
      state.isLoading = false;
      state.usersListByStatus = state.usersListByStatus.filter((data) => data.id !== action.payload);
    },
  },
});

export default employee.reducer;
export const {
  startLoading,
  hasError,
  getEmployee,
  clearEmployeeList,
  updateEmployee,
  getEmployeeById,
  getVendoredesignationID,
  createEmployeeSuccess,
  updateEmployeeSuccess,
  getAssignedVendorForEmployee,
  getUnassignedVendorForEmployee,
  getEmployeeAssignemnt,
  getEmployeeFolloups,
  getEmployeeOrder,
  getEmployeeTraking,
  addNewEmployeeSuccess,
  getEmployeeDiscussion,
  editEmployeeSuccess,
  getEmployeeTarget,
  getAllEmployeeTarget,
  getEmployeeActivity,
  addNewEmployeeTargetSuccess,
  editEmployeeTargetSuccess,
  employeeBulkUpload,
  deleteEmployee,
  getEmployeeDesignationList,
  getAttendanceList,
  downloadAttendanceReport,
  getChildUnassignEmployees,
  getChildAssignEmployees,
  getChildDesignation,
  getResponse,
  setNoData,
  getEmployeeTargetById,
  configureTargetData,
  getEmployeeAllActivity,
  updateEmployeeTargetReducer,
  getVendorDesignationByEmployee,
  getCurrentEmployeeDesignation,
  deleteEmployeeTarget,
  getDeletedEmployeeByDesignationReducer,
  getUsersByStatusReducer,
  reduceUserFromRegistered,
} = employee.actions;
