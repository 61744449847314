import { call, put } from 'redux-saga/effects';
import axios from '../../../utils/axios';
import {
  startLoading,
  getEmployee,
  clearEmployeeList,
  getEmployeeDesignationList,
  getEmployeeTarget,
  getEmployeeDiscussion,
  getEmployeeFolloups,
  getEmployeeAssignemnt,
  getEmployeeOrder,
  getEmployeeById,
  getEmployeeActivity,
  getEmployeeTraking,
  getChildUnassignEmployees,
  getChildAssignEmployees,
  getChildDesignation,
  getResponse,
  getVendoredesignationID,
  getAssignedVendorForEmployee,
  getUnassignedVendorForEmployee,
  deleteEmployee,
  setNoData,
  getEmployeeAllActivity,
  getVendorDesignationByEmployee,
  updateEmployee,
} from '../../slices/employee';
import { GettingRoleID } from '../../../components/GettingRoleID';
import {
  setResponse,
  hasError,
  startFormLoading,
  stopFormLoading,
  getErrorMessage,
  getDeleteMessage,
  startAssignmentLoader,
  stopAssignmentLoader,
  getSuccessMessage,
} from '../../slices/responseStatus';
import { BASEURL } from '../../../BaseUrl/BaseUrl';
import { isLogout } from '../../slices/login';

export function* employeeListSaga(state) {
  try {
    const { regionId } = state;
    yield put(hasError(false));
    yield put(startLoading(true));
    yield put(startFormLoading());

    const Token = window.localStorage.getItem('auth-token');
    const id = GettingRoleID('employee');
    const response = yield axios.get(`${BASEURL}/users/?role=${id}&region=${regionId}`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    const user = response?.data?.data?.users?.user;
    if (user === undefined) {
      yield put(getEmployee([]));
    } else {
      yield put(getEmployee(user));
    }
    yield put(stopFormLoading());
  } catch (error) {
    yield put(startLoading(false));
    yield put(stopFormLoading());

    yield put(getEmployee([]));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}

export function* employeeListSagaForView(state) {
  try {
    const { regionId, designationId, rowsPerPage, page } = state;
    yield put(hasError(false));
    yield put(startLoading(true));
    yield put(startFormLoading());

    const Token = window.localStorage.getItem('auth-token');
    const model = localStorage.getItem('employeeModal');
    const id = GettingRoleID('employee');

    const addParams = `${designationId ? `&designation=${designationId}` : ''}`;

    const getUrl = {
      active: `/users/?role=${id}&region=${regionId}&status=Active${addParams}&limit=${rowsPerPage}&page=${page + 1}`,
      inactive: `/users/?role=${id}&region=${regionId}&status=Inactive${addParams}&limit=${rowsPerPage}&page=${page + 1}`,
      deleted: `/users/?role=${id}&region=${regionId}&delete=true${addParams}&limit=${rowsPerPage}&page=${page + 1}`,
    };
    const response = yield axios.get(getUrl[model], {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });

    // eslint-disable-next-line prefer-destructuring
    const user = response?.data?.data?.users?.user;
    const count = response?.data?.data?.users?.totalRowsValue;
    if (user === undefined) {
      yield put(getEmployee([]));
    } else {
      yield put(clearEmployeeList());
      yield put(getEmployee({ user, count }));
    }
    yield put(stopFormLoading());
  } catch (error) {
    yield put(startLoading(false));
    yield put(stopFormLoading());

    yield put(getEmployee([]));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}
export function* inactiveemployeeListSaga(state) {
  try {
    const { regionId } = state;
    yield put(hasError(false));
    yield put(startLoading(true));
    yield put(startFormLoading());

    const Token = window.localStorage.getItem('auth-token');
    const id = GettingRoleID('employee');
    const response = yield axios.get(`${BASEURL}/users/?role=${id}&region=${regionId}&status=inactive`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });

    // eslint-disable-next-line prefer-destructuring
    const user = response?.data?.data?.users?.user;
    if (user === undefined) {
      yield put(getEmployee([]));
    } else {
      yield put(getEmployee(user));
    }
    yield put(stopFormLoading());
  } catch (error) {
    yield put(startLoading(false));
    yield put(stopFormLoading());

    yield put(getEmployee([]));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}
export function* deleteEmployeeRowSaga(state) {
  try {
    yield put(startLoading(true));
    yield put(hasError(false));
    const { id } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.delete(`${BASEURL}/users/${id}`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    if (response.status === 200) {
      yield put(deleteEmployee(id));
    }
    // yield call(employeeListSaga);
    yield put(startLoading(false));
    yield put(getDeleteMessage(response.data.meta.message));
  } catch (error) {
    yield put(startLoading(false));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* addEmployeeListSaga(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { item } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.post(`${BASEURL}/users`, item, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    yield put(getSuccessMessage(response.data.meta.message));
    yield put(setResponse(response.status));
    yield call(employeeListSaga, '');
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* editEmployeeListSaga(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { index, items, StatusChange } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.put(`${BASEURL}/users/${index}`, items, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    if (StatusChange !== 'StatusChange') {
      yield put(getSuccessMessage(response.data.meta.message));
      yield put(setResponse(response.status));
      yield put(updateEmployee(state?.items));
    }

    yield put(stopFormLoading());
    yield call(employeeListSaga, '');
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* getEmployeeDesignation() {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/designations/?dept=employee`, {
      headers: {
        'x-auth-token': `${Token}`,
        'Cache-Control': 'no-cache',
      },
    });
    const designationsData = response.data.data.Designations.designation;
    yield put(getEmployeeDesignationList(designationsData === undefined ? [] : designationsData));
    yield put(stopFormLoading());
  } catch (error) {
    yield put(startLoading(false));
    yield put(stopFormLoading());
    yield put(hasError(true));

    yield put(getErrorMessage(error.error.message));
  }
}

export function* employeeTargetSaga(state) {
  try {
    const { id, model, date, startDate, endDate, isEmployeeTarget } = state;
    const currentDay = new Date();
    const currentMonth = (currentDay.getMonth() + 1).toString().padStart(2, '0');
    const currnetYear = currentDay.getFullYear();
    const getAdmin = JSON.parse(localStorage.getItem('admin') || '[]');
    yield put(hasError(false));
    yield put(startFormLoading());
    const Token = window.localStorage.getItem('auth-token');
    if (model === 'Details') {
      const getUserByID = yield axios.get(`${BASEURL}/users/${id}`, {
        headers: {
          'x-auth-token': `${Token}`,
        },
      });

      yield put(stopFormLoading());
      const currentuser = getUserByID.data.data?.user[0];
      yield put(getEmployeeById(currentuser));
    }
    if (isEmployeeTarget) {
      const response = yield axios.get(`${BASEURL}/targets/${id}?month=${currentMonth}&year=${currnetYear}`, {
        headers: {
          'x-auth-token': `${Token}`,
        },
      });
      const { TotalRevenue } = response.data.data;
      yield put(getEmployeeTarget(TotalRevenue));
      yield put(stopFormLoading());
    }

    if (model === 'Activity') {
      if (date === undefined) {
        const date = new Date();
        const currentDate = new Date(date).toISOString().substr(0, 10);

        const response = yield axios.get(`${BASEURL}/history/${id}?date=${currentDate}`, {
          headers: {
            'x-auth-token': `${Token}`,
          },
        });
        // const traking = yield axios.get(`${BASEURL}/userTracking/${id}`, {
        //   headers: {
        //     'x-auth-token': `${Token}`,
        //   },
        // });
        const { history } = response.data.data;
        const { activities } = response.data.data.history;
        let tracking;
        if (response?.data?.data?.history?.live_tracking.length > 0) {
          tracking = response?.data?.data?.history?.live_tracking[0]?.tracking;
        } else {
          tracking = [];
        }
        yield put(getEmployeeAllActivity(history));
        yield put(getEmployeeActivity(activities === undefined ? [] : activities));

        yield put(getEmployeeTraking(tracking));
      } else {
        yield put(getEmployeeTraking([]));
        const response = yield axios.get(`${BASEURL}/history/${id}?date=${date}`, {
          headers: {
            'x-auth-token': `${Token}`,
          },
        });
        const { activities } = response.data.data.history;
        // yield put(getEmployeeActivity(activities === undefined ? [] : activities));
        // const traking = yield axios.get(`${BASEURL}/userTracking/${id}?date=${date}`, {
        //   headers: {
        //     'x-auth-token': `${Token}`,
        //   },
        // });
        // yield put(getEmployeeActivity(UserActivities));
        const { history } = response.data.data;

        yield put(getEmployeeAllActivity(history));

        yield put(getEmployeeActivity(activities === undefined ? [] : activities));
        let tracking;
        if (response?.data?.data?.history?.live_tracking.length > 0) {
          tracking = response?.data?.data?.history?.live_tracking[0]?.tracking;
        } else {
          tracking = [];
        }

        yield put(getEmployeeTraking(tracking));
        // yield put(getEmployeeTraking(locations));
      }
    }
    if (model === 'discussion') {
      const response = yield axios.get(`${BASEURL}/userActivities/user/${id}?event_type=discussion`, {
        headers: {
          'x-auth-token': `${Token}`,
        },
      });
      const { UserActivities } = response.data.data;

      yield put(stopFormLoading());
      yield put(getEmployeeDiscussion(UserActivities === undefined ? [] : UserActivities));
    }
    if (model === 'Orders') {
      const response = yield axios.get(
        `${BASEURL}/userActivities/user/${id}?event_type=order&startDate=${startDate || new Date().toISOString().substr(0, 10)
        }&endDate=${endDate || new Date().toISOString().substr(0, 10)}`,
        {
          headers: {
            'x-auth-token': `${Token}`,
          },
        }
      );
      const { UserActivities } = response.data.data;
      yield put(getEmployeeOrder(UserActivities === undefined ? [] : UserActivities));
    }
    if (model === 'Followups') {
      const response = yield axios.get(`${BASEURL}/userActivities/user/${id}?event_type=reminder`, {
        headers: {
          'x-auth-token': `${Token}`,
        },
      });
      const { UserActivities } = response.data.data;
      yield put(getEmployeeFolloups(UserActivities === undefined ? [] : UserActivities));
    }
    if (model === 'Assignments') {
      yield axios.get(`${BASEURL}/userActivities/user/${getAdmin.id}?event_type=assignments`, {
        headers: {
          'x-auth-token': `${Token}`,
        },
      });

      yield put(getEmployeeAssignemnt([]));
    }
    yield put(stopFormLoading());
  } catch (error) {
    yield put(hasError(true));

    yield put(getErrorMessage(error.error.message));
  }
}

export function* employeeBulkUpload(state) {
  try {
    yield put(startLoading(true));
    yield put(hasError(false));
    const { item } = state;
    const Token = window.localStorage.getItem('auth-token');
    yield axios.post(`${BASEURL}/users/upload`, item, {
      headers: {
        'x-auth-token': `${Token}`,
        'content-type': 'multipart/form-data',
      },
    });
    yield call(employeeListSaga, '');
  } catch (error) {
    yield put(startLoading(false));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* childDesignationForEmployeeSaga(state) {
  try {
    const { currentDesignationID } = state;
    yield put(hasError(false));
    // yield put(startFormLoading());
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/hierarchies/designation/${currentDesignationID}?dept=employee`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    const childDesignationID = response.data.data.designations[0].id;

    yield put(getChildDesignation(childDesignationID));
  } catch (error) {
    yield put(hasError(true));

    yield put(getErrorMessage(error.error.message));
  }
}

export function* unassignEmployeeSaga(state) {
  try {
    const { childDesignation, id } = state;
    const Token = window.localStorage.getItem('auth-token');
    if (childDesignation && childDesignation !== null) {
      const unassignEmployeeResponse = yield axios.get(
        // child designaitonid
        `${BASEURL}/userManagement/unassigned/?child_designation_id=${childDesignation}&role=employee&user_id=${id}&child=true`,
        {
          headers: {
            'x-auth-token': `${Token}`,
          },
        }
      );
      yield put(getChildUnassignEmployees(unassignEmployeeResponse.data.data.Unassigned_users));
    }
  } catch (error) {
    yield put(hasError(true));

    yield put(getErrorMessage(error.error.message));
  }
}

export function* assignEmployeeSaga(state) {
  try {
    yield put(startFormLoading());
    yield put(setNoData(false));

    const { id } = state;
    const Token = window.localStorage.getItem('auth-token');
    // user id
    const response = yield axios.get(`${BASEURL}/userManagement/${id}`, {
      headers: {
        'x-auth-token': `${Token}`,
        'Cache-Control': 'no-cache',
      },
    });
    const data = response.data.data.Employees;
    yield put(getChildAssignEmployees(data));
    if (data.length === 0) {
      yield put(setNoData(true));
    }
    yield put(stopFormLoading());
  } catch (error) {
    yield put(hasError(true));

    yield put(getErrorMessage(error.error.message));
  }
}

export function* updateAssignUnassignSaga(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { item, id } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.put(`${BASEURL}/userManagement/${id}`, item, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    yield put(getResponse(response.status));
    yield put(stopFormLoading());
  } catch (error) {
    yield put(hasError(true));

    yield put(getErrorMessage(error.error.message));
  }
}

export function* employeeTovendorHierarchy(state) {
  try {
    const { DesignationID } = state;
    yield put(hasError(false));
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/hierarchies/emp-vendor-designation/${DesignationID}`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });

    // const { designations } = response.data.data;

    const designationsData = response?.data?.data?.designations;
    yield put(getVendoredesignationID(designationsData || null));
    // yield call(vendorsListSaga);
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* assignunassignvendortoemployee(state) {
  try {
    yield put(startAssignmentLoader());
    const { childDesignationID, currentUserId, currentEmployeeDesignation } = state;
    yield put(hasError(false));
    const Token = window.localStorage.getItem('auth-token');

    if (currentUserId && (currentUserId !== undefined || null)) {
      const getUnassignuser = yield axios.get(
        `${BASEURL}/userManagement/unassigned/?child_designation_id=${childDesignationID}&user_id=${currentUserId}&role=employee`,
        {
          headers: {
            'x-auth-token': `${Token}`,
          },
        }
      );
      const unassigned = getUnassignuser.data.data.Unassigned_users;
      yield put(getUnassignedVendorForEmployee(unassigned === undefined ? [] : unassigned));
    } else if (currentEmployeeDesignation !== undefined) {
      const getUnassignuser = yield axios.get(
        `${BASEURL}/userManagement/unassigned/?child_designation_id=${childDesignationID}&role=employee`,
        {
          headers: {
            'x-auth-token': `${Token}`,
          },
        }
      );
      const unassigned = getUnassignuser.data.data.Unassigned_users;
      yield put(getUnassignedVendorForEmployee(unassigned === undefined ? [] : unassigned));
    }
    if (currentUserId !== undefined || null) {
      const getAssignuser = yield axios.get(
        `${BASEURL}/userManagement/vendors/${currentUserId}?designation_id=${childDesignationID}&role=employee`,
        {
          headers: {
            'x-auth-token': `${Token}`,
          },
        }
      );
      const assigned = getAssignuser.data.data.Vendors;
      yield put(getAssignedVendorForEmployee(assigned === undefined ? [] : assigned));
    }

    yield put(stopAssignmentLoader());
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* getVendorsByEmployeeDesignation(state) {
  try {
    const { DesignationID } = state;
    yield put(hasError(false));
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/hierarchies/vendors-under-employee/${DesignationID}`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    const designationsData = response?.data?.data.vendors;
    yield put(getVendorDesignationByEmployee(designationsData));
    yield put(stopFormLoading());
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
