import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem } from '@mui/material';
import { dispatch, useSelector } from '../../../redux/store';
import sagaActions from '../../../redux/actions';

// routes
import { PATH_AUTH } from '../../../routes/paths';

// components
import Avatar from '../../../components/Avatar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import SettingMode from '../../../components/settings/SettingMode';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const { user, ISlogout } = useSelector((state) => state.login);
  // const LocalStorageDetail = window.localStorage.getItem('admin');
  // const userDetail = JSON.parse(LocalStorageDetail || '');
  const LocalStorageDetail = window.localStorage.getItem('admin');
  const userDetail = JSON.parse(LocalStorageDetail || null);
  const [open, setOpen] = useState(null);
  const admin = user || userDetail;
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    if (ISlogout) {
      navigate(PATH_AUTH.login, { replace: true });
    }
    // eslint-disable-next-line
  }, [ISlogout]);

  const handleLogout = () => {
    dispatch({ type: sagaActions.LOG_OUT });
    handleClose();
  };
  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[700], 0.5),
            },
          }),
        }}
      >
        <Avatar name={admin?.first_name || ''} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          border: ' 0.5px solid rgb(145 158 171 / 20%)',
          boxShadow: 0,
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {admin?.first_name || 'loadin...'}
            {admin?.last_name || 'loading...'}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {admin?.designation || 'loading...'}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          <Box>
            <SettingMode />
          </Box>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
