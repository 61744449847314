import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
// hooks
import { useSelector } from '../redux/store';
import Tostify from '../components/Tostify';
import { PATH_AUTH } from '../routes/paths';
import useManageSidebar from '../hooks/useManageSidebar';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const data = window.localStorage.getItem('auth-token');
  const { error } = useSelector((state) => state.login);
  const { collectSidebarContentAsPerRole } = useManageSidebar();
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const url = pathname;
  const parts = url.split('/');
  const moduleName = parts[2];

  const [requestedLocation, setRequestedLocation] = useState(null);
  const isAuthorisedPage = collectSidebarContentAsPerRole.find((module) => moduleName === module.modulePath);
  useEffect(() => {
    if (!isAuthorisedPage) {
      navigate('/admin/UnauthorizedPage');
    }
  }, [isAuthorisedPage, navigate]);

  if (error) {
    <Tostify status={error} />;
    window.localStorage.removeItem('auth-token');
  }

  if (data === null || error) {
    return <Navigate to={PATH_AUTH.login} />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
