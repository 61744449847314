// routes
import React from 'react';
import { PATH_DASHBOARD } from '../../../routes/paths';
import SvgIconStyle from '../../../components/SvgIconStyle';

const getIcon = (name) => <SvgIconStyle src={ `/icons/${name}.svg` } sx={ { width: 1, height: 1 } } />;

const ICONS = {
  dashboard: getIcon('ic_home_dashboard'),
  inventory: getIcon('ic_inventory'),
  vendors: getIcon('ic_vendors'),
  customers: getIcon('ic_customers'),
  leads: getIcon('ic_leads'),
  order: getIcon('ic_order'),
  employees: getIcon('ic_employees'),
  invoice: getIcon('ic_invoice'),
  setting: getIcon('ic_setting'),
  regionMaster: getIcon('region_master'),
  packageManagement: getIcon('package_management'),
  UnitMaster: getIcon('unit_master'),
  routeIcon: getIcon('ic_route'),
  discountIcon: getIcon('ic_discount'),
  customerIcon: getIcon('ic_customer'),
  farmerIcon: getIcon('ic_farmer'),
  dealerIcon: getIcon('ic_dealer'),
  meetingIcon: getIcon('ic_meeting'),
};

const prepareSidebarValueWithPath = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard, modulePath: 'dashboard' },
      { title: 'Lead', path: PATH_DASHBOARD.lead.root, icon: ICONS.leads, modulePath: 'leads' },
      { title: 'Order', path: PATH_DASHBOARD.order.root, icon: ICONS.order, modulePath: 'orders' },
      { title: 'Invoice', path: PATH_DASHBOARD.invoice.root, icon: ICONS.invoice, modulePath: 'invoice' },
      { title: 'Vendors', path: PATH_DASHBOARD.general.vendor, icon: ICONS.vendors, modulePath: 'vendor' },
      { title: 'Meeting', path: PATH_DASHBOARD.meeting.root, icon: ICONS.routeIcon, modulePath: 'meeting' },
      {
        title: 'Inventory',
        path: PATH_DASHBOARD.inventory.root,
        icon: ICONS.inventory,
        children: [
          { title: 'Category', path: PATH_DASHBOARD.inventory.category },
          { title: 'Product', path: PATH_DASHBOARD.inventory.products },
          { title: 'Stock Management', path: PATH_DASHBOARD.inventory.stock },
          { title: 'Price List', path: PATH_DASHBOARD.inventory.price },
        ],
        modulePath: 'inventory',
      },
      { title: 'Discount', path: PATH_DASHBOARD.discount.root, icon: ICONS.discountIcon, modulePath: 'discount' },
      {
        title: 'Employees',
        path: PATH_DASHBOARD.employee.root,
        icon: ICONS.employees,
        children: [
          { title: 'Employees list', path: PATH_DASHBOARD.employee.employeelist },
          { title: 'Registered', path: PATH_DASHBOARD.employee.registered },
          { title: 'Rejected', path: PATH_DASHBOARD.employee.rejected },
          { title: 'Employees target', path: PATH_DASHBOARD.employee.employeetarget },
          { title: 'Attendance report', path: PATH_DASHBOARD.employee.attendance },
        ],
        modulePath: 'employee',
      },
      {
        title: 'Designations',
        path: PATH_DASHBOARD.designation.root,
        icon: ICONS.customers,
        children: [
          { title: 'Designation List', path: PATH_DASHBOARD.designation.designationlist },
          { title: 'Hierarchy', path: PATH_DASHBOARD.designation.hierarchy },
          { title: 'Designation Relation', path: PATH_DASHBOARD.designation.designation_relation },
        ],
        modulePath: 'designation',
      },
      { title: 'Route', path: PATH_DASHBOARD.route.root, icon: ICONS.routeIcon, modulePath: 'route' },
      {
        title: 'Package',
        path: PATH_DASHBOARD.packageManagement.root,
        icon: ICONS.packageManagement,
        modulePath: 'packageList',
      },
      {
        title: 'Unit',
        path: PATH_DASHBOARD.unitMaster.root,
        icon: ICONS.UnitMaster,
        children: [
          { title: 'Unit Master', path: PATH_DASHBOARD.unitMaster.unitMaster },
          { title: 'Hierarchy', path: PATH_DASHBOARD.unitMaster.hierarchy },
        ],
        modulePath: 'unit',
      },
      {
        title: 'Region Master',
        path: PATH_DASHBOARD.regionMaster.root,
        icon: ICONS.regionMaster,
        modulePath: 'regionList',
      },
      { title: 'Profile', path: PATH_DASHBOARD.profile.root, icon: ICONS.vendors, modulePath: 'profile' },
      {
        title: 'settings',
        path: PATH_DASHBOARD.settings.root,
        icon: ICONS.setting,
        children: [
          { title: 'Feature Approval', path: PATH_DASHBOARD.settings.featureApproval },
          { title: 'Invoice number pattern', path: PATH_DASHBOARD.settings.invoiceNumberPattern },
          { title: 'Notification', path: PATH_DASHBOARD.settings.notification },
          { title: 'Negative Stock', path: PATH_DASHBOARD.settings.negativeStock },
        ],
        modulePath: 'settings',
      },
    ],
  },
];

const navConfig = prepareSidebarValueWithPath;
export default navConfig;
