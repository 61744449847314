import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { PATH_DASHBOARD } from '../routes/paths';

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const isAuthenticated = window.localStorage.getItem('auth-token');

  if (isAuthenticated) {
    return <Navigate to={PATH_DASHBOARD.employee.employeelist} />;
  }

  return <>{children}</>;
}
