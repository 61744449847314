import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  invoicePatternString: undefined,
  invoiceList: [],
  sidebarLoader: false,
  sidebarContent: [],
};

const invoiceSlice = createSlice({
  name: 'invoiceSlice',
  initialState,
  reducers: {
    getInvoicePatternString(state, action) {
      state.isLoading = false;
      state.invoicePatternString = action.payload;
    },
    startLoading(state, action) {
      state.isLoading = action.payload;
    },
    getAllInvoice(state, action) {
      state.isLoading = false;
      state.invoiceList = action.payload;
    },
    addNewInvoiceLocally(state, action) {
      state.isLoading = false;
      state.invoiceList = [...state.invoiceList, action.payload];
    },
    editInvoiceLocally(state, action) {
      state.invoiceList = state.invoiceList.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...action.payload.data,
            id: action.payload.id,
          };
        }
        return item;
      });
    },
    deleteInvoice(state, action) {
      state.invoiceList = state.invoiceList.filter((data) => data.id !== action.payload);
    },
    startSidebarLoader(state, action) {
      state.sidebarLoader = action.payload;
    },
    getSidebarContent(state, action) {
      state.sidebarLoader = false;
      state.sidebarContent = action.payload;
    },
  },
});
export default invoiceSlice.reducer;
export const {
  getInvoicePatternString,
  startLoading,
  getAllInvoice,
  deleteInvoice,
  addNewInvoiceLocally,
  editInvoiceLocally,
  startSidebarLoader,
  getSidebarContent,
} = invoiceSlice.actions;
